import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import img1 from '../../Images/fotopechat/1.png'
import img2 from '../../Images/fotopechat/2.png'

import img3 from '../../Images/fotopechat/3.png'
import img4 from '../../Images/fotopechat/4.png'
import img5 from '../../Images/fotopechat/5.png'
import img6 from '../../Images/fotopechat/6.png'
import img7 from '../../Images/fotopechat/7.png'
import img8 from '../../Images/fotopechat/8.png'
import img9 from '../../Images/fotopechat/9.png'
import img10 from '../../Images/fotopechat/10.png'



import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useState } from "react";

const Fotopechat = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <div className="top">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          <SwiperSlide>
            <img className="w-100" alt="" src={img1} />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img2}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img3}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img4}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img5}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img6}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img7}  />
          </SwiperSlide><SwiperSlide>
            <img className="w-100" alt="" src={img8}  />
          </SwiperSlide><SwiperSlide>
            <img className="w-100" alt="" src={img9}  />
          </SwiperSlide><SwiperSlide>
            <img className="w-100" alt="" src={img10}  />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="bottom">
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img className="w-100" alt="" src={img1}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img2}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img3}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img4}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img5}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img6}  />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-100" alt="" src={img7}  />
          </SwiperSlide><SwiperSlide>
            <img className="w-100" alt="" src={img8}  />
          </SwiperSlide><SwiperSlide>
            <img className="w-100" alt="" src={img9}  />
          </SwiperSlide><SwiperSlide>
            <img className="w-100" alt="" src={img10}  />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default Fotopechat;
