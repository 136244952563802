import { useState } from "react";
import odnaton from '../Images/odnatonny.png'
import matovy from '../Images/matovy.png'
import foto from '../Images/fotopechat.png'
import zvyozdny from '../Images/zvyozdnoe.png'
import Fade from 'react-reveal/Fade';

import Odnotonoviy from "./portfolio/Odnotonoviy";
import Fotopechat from "./portfolio/Fotopechat";
import Zvyozdny from "./portfolio/Zvyozdny";
import Matovy from "./portfolio/Matovy";

export default function Portfolio() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);


  return (
    <>
      <section id="work" className="Portfolio">
        <div className="container">
          <h2 className="portfolio-tittle">НАШИ РАБОТЫ</h2>
          

          <div className="porfolio-blog">
            <Fade left>
              <div onClick={() => setIsOpen2(true)} className="portfolio-blog__card">
                <img src={foto} alt="НАТЯЖНЫЕ ПОТОЛКИ" />
                <h2>Потолки с фотопечатью</h2>
                <p>
                  Посмотреть весь каталог <div className="stick"></div>
                </p>
              </div>
            </Fade>

            <Fade right>
              <div onClick={() => setIsOpen3(true)} className="portfolio-blog__card">
                <img src={zvyozdny} alt="НАТЯЖНЫЕ ПОТОЛКИ" />
                <h2>Звездное небо</h2>
                <p>
                  Посмотреть весь каталог <div className="stick"></div>
                </p>
              </div>
            </Fade>

            
          </div>

          <div className="porfolio-blog">
            <Fade left>

              <div
                onClick={() => setIsOpen1(true)}
                className="portfolio-blog__card"
              >
                <img src={odnaton} alt="НАТЯЖНЫЕ ПОТОЛКИ" />
                <h2>Однотонные потолки </h2>
                <p>
                  Посмотреть весь каталог <div className="stick"></div>
                </p>
              </div>
            </Fade>

            <Fade right>
              <div onClick={() => setIsOpen4(true)} className="portfolio-blog__card">
                <img src={matovy} alt="Матовые натяжные потолки" />
                <h2>Матовые потолки</h2>
                <p>
                  Посмотреть весь каталог <div className="stick"></div>
                </p>
              </div>
            </Fade>
            


            
          </div>
        </div>
      </section>
      <div className={`mySwiperWrap ${isOpen1 && "active"}`}>
        <Odnotonoviy />
        <div onClick={() => setIsOpen1(false)} className="bg"></div>
      </div>

      <div className={`mySwiperWrap ${isOpen2 && "active"}`}>
        <Fotopechat />
        <div onClick={() => setIsOpen2(false)} className="bg"></div>
      </div>

      <div className={`mySwiperWrap ${isOpen3 && "active"}`}>
        <Zvyozdny />
        <div onClick={() => setIsOpen3(false)} className="bg"></div>
      </div>

      <div className={`mySwiperWrap ${isOpen4 && "active"}`}>
        <Matovy />
        <div onClick={() => setIsOpen4(false)} className="bg"></div>
      </div>
    </>
  );
}
